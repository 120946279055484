<template>
    
    <div class="h-full w-full overflow-hidden flex flex-col bg-bg-darkmode">
        
        <div class="h-12 w-full flex flex-row justify-center items-center">
            <span class="text-xl font-semibold" :class="'text-dfont-darkmode'">TRANSLATIONS</span>
        </div>

        <div class="h-auto flex">

            <div v-for="(lang, index) in langs" :key="index" class="h-full w-1/5 flex-none">

                <div class="h-20 flex flex-row justify-center items-center sticky top-0" :class="'bg-bg-darkmode'">
                    <span class="font-semibold uppercase" :class="'text-dfont-darkmode'">{{ lang }}</span>
                </div>

            </div>
        </div>

        <div class="flex flex-1 min-h-0 overflow-auto px-4">

            <div v-for="(lang, index) in langs" :key="index" class="h-full w-1/5 flex-none">

                <div v-for="(el, indexes) in es" :key="indexes" class="h-32 p-4" :class="{'bg-module-darkmode': indexes%2 == 0}">
                    <textarea type="text" :value="getList(lang)[el]" class="h-full p-2 w-full rounded bg-box-darkmode text-dfont-darkmode"/>
                </div>

            </div>

        </div>

        <div class="h-20 bg-bg-darkmode flex flex-row justify-end items-center px-4">

            <div class="h-12 w-auto px-4 bg-darkgreen-darkmode shadow-pamesa flex flex-row justify-center items-center rounded cursor-pointer" @click="apply()">
                <span class="text-dfont-darkmode ">{{ $t('apply') }}</span>
            </div>

        </div>

    </div>

</template>

<script>
import { state } from '@/store';
import es from '../../i18n/es/messages';
import en from '../../i18n/en/messages';
import de from '../../i18n/de/messages';
import fr from '../../i18n/fr/messages';
import it from '../../i18n/it/messages';

export default {
    data(){
        return{
            langs: ['es', 'en', 'de', 'fr', 'it'],
            es_list:[],
            en_list:[],
            de_list:[],
            fr_list:[],
            it_list:[],
            es: Object.keys(es)
        }
    },
    methods:{
        getList(lang){

            if(lang == 'es'){
                return this.es_list
            }

            if(lang == 'en'){
                return this.en_list
            }

            if(lang == 'de'){
                return this.de_list
            }

            if(lang == 'fr'){
                return this.fr_list
            }

            if(lang == 'it'){
                return this.it_list
            }
            
        },
        apply(){

            console.log(this.es_list['forgottenPassword'])

            // this.es_list.forEach((element,index) => {
                
                // console.log(element)
                // console.log(es[element] + ' - ' + this.es_list[element])

            // });

        }
    },
    computed:{
        mode(){
            return state.mode
        }
    },
    created(){
        this.es_list = es
        this.en_list = en
        this.de_list = de
        this.fr_list = fr
        this.it_list = it
    }
}

</script>

<style scoped>

</style>